/**
 * Custom element for formatting and validating username/code names
 * @element username-input
 * @attr {string} label - The label for the input (default: 'Account Code')
 * @attr {string} placeholder - The placeholder text to display in the input
 */
export class UsernameInput extends HTMLElement {
  constructor () {
    super()
    this.input = null
    this.label = this.getAttribute('label') || 'Account Code'
    this.placeholder = this.getAttribute('placeholder') || ''
  }

  connectedCallback () {
    this.input = this.querySelector('input[type="text"]')

    if (!this.input) {
      console.error('UsernameInput: No input element found. Please include an input element of type "text".')
      return
    }

    // Set attributes for accessibility and usability
    this.input.setAttribute('aria-label', this.input.getAttribute('aria-label') || this.label)
    this.input.setAttribute('autocomplete', 'off')
    this.input.setAttribute('placeholder', this.placeholder)

    this.input.addEventListener('input', this.formatUsername.bind(this))
    this.input.addEventListener('keydown', this.handleKeyDown.bind(this))
    this.input.addEventListener('blur', this.validateUsername.bind(this))
  }

  // Format the username as it is typed, containing only alphanumeric characters, hyphens, and underscores
  formatUsername () {
    const username = this.input.value
     // Replace multiple underscores with a single underscore
    this.input.value = username
      .toLowerCase()
      .replace(/\s/g, '') // Remove spaces
      .replace(/[^a-z0-9-_]/g, '') // Remove characters that are not alphanumeric, hyphens, or underscores
      .replace(/-{2,}/g, '-') // Replace multiple hyphens with a single hyphen
      .replace(/_{2,}/g, '_')
  }

  handleKeyDown (event) {
    const allowedChars = /^[a-zA-Z0-9-_]$/
    const key = event.key

    if (key === 'Tab') {
      return
    }

    if (!allowedChars.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
      event.preventDefault()
    }
  }

  validateUsername () {
    const username = this.input.value
    // Default pattern allows alphanumeric characters, hyphens, and underscores between 3 and 63 characters,
    // must start with an alphanumeric character, and may not contain consecutive hyphens or underscores
    const regex = /^(?=[a-zA-Z0-9])[a-zA-Z0-9-_]{1,62}[a-zA-Z0-9]$/

    if (!regex.test(username)) {
      this.input.setCustomValidity('Please enter a valid username')
      this.input.setAttribute('aria-invalid', 'true')
    } else {
      this.input.setCustomValidity('')
      this.input.removeAttribute('aria-invalid')
    }
    // this.input.reportValidity();
  }

  // Getter for the formatted username
  get value () {
    return this.input ? this.input.value : ''
  }

  // Setter for the username
  set value (newValue) {
    if (this.input) {
      this.input.value = newValue
      this.formatUsername()
    }
  }

  // Observe attributes for changes
  static get observedAttributes () {
    return ['label', 'placeholder']
  }

  // Handle attribute changes
  attributeChangedCallback (name, oldValue, newValue) {
    if (oldValue !== newValue) {
      this[name] = newValue
      if (this.input) {
        if (name === 'label') {
          this.input.setAttribute('label', newValue)
        } else if (name === 'placeholder') {
          this.input.setAttribute('placeholder', newValue)
        }
        this.formatUsername()
      }
    }
  }
}
