// JS
import htmx from 'htmx.org/dist/htmx.esm.js'
import Sortable from 'sortablejs'
import {
  BusyWrapper,
  CountSelector,
  CopyToClipboard,
  DropdownMenu,
  FileUpload,
  MobileMenu,
  PasswordToggle,
  PhoneNumberInput,
  ReservationSelector,
  SelectorBox,
  TopicCard,
  TopicSelector,
  UsernameInput
} from './components/index.js'

window.htmx = htmx

// Config object
const config = {
  debounceDelay: 350,
  waitForComponents: [],
}

const componentsMap = {
  'busy-wrapper': BusyWrapper,
  'count-selector': CountSelector,
  'copy-to-clipboard': CopyToClipboard,
  'dropdown-menu': DropdownMenu,
  'file-upload': FileUpload,
  'mobile-menu': MobileMenu,
  'password-toggle': PasswordToggle,
  'phone-number-input': PhoneNumberInput,
  'reservation-selector': ReservationSelector,
  'selector-box': SelectorBox,
  'topic-card': TopicCard,
  'topic-selector': TopicSelector,
  'username-input': UsernameInput,
}

// Helper function to check if element is a button
function isButton(element) {
  return element.tagName === 'BUTTON' ||
    element.classList.contains('button');
}

// Handle buttons with hx-confirm or hx-prompt attributes
// This will prevent the button from being set to aria-busy
// until the confirm or prompt is resolved
function handleConfirmPromptButton(element) {
  if (!isButton(element)) return;
  if (!element.hasAttribute('hx-confirm') && !element.hasAttribute('hx-prompt')) return;

  // Only handle buttons with confirm/prompt
  element.removeAttribute('aria-busy');
}

// Scroll to the first error on the page. This is used when the page is reloaded after a form submission
// via HTMX. If the body has a data-menu-offset attribute, it will be used to offset the scroll position.
function scrollToFirstError() {
  const firstError = document.querySelector('.form-group.error');
  if (firstError) {
    const offset = document.body.dataset.menuOffset ? parseInt(document.body.dataset.menuOffset || '0') : 0;
    const rect = firstError.getBoundingClientRect();
    const scrollPosition = window.scrollY + rect.top - offset;

    window.scrollTo({
      top: scrollPosition,
     behavior: 'smooth'
    });
  }
}

// Handle flash message close button
window.closeFlash = (button) => {
  const flashDiv = button.closest('#FlashMessage')
  flashDiv.classList.add('translate-x-full')
  setTimeout(() => {
    flashDiv.remove()
  }, 300)
}

// Show flash messages if they exist
const showFlashMessages = () => {
  const flash = document.querySelector('#FlashMessage')
  if (flash) {
    setTimeout(() => {
      flash.classList.remove('hide')
    }, 100)

    const autoClose = flash.dataset.autoClose
    if (autoClose) {
      setTimeout(() => {
        flash.classList.add('hide')
      }, parseInt(autoClose))
    }
  }
}

// Handle htmx abort event
document.body.addEventListener('htmx:abort', function (evt) {
  handleConfirmPromptButton(evt.detail.elt)
})

// Handle htmx beforeRequest event
document.body.addEventListener('htmx:beforeRequest', function (evt) {
  // Clear error container when starting new request
  const errorContainer = document.getElementById('ErrorContainer')
  if (errorContainer) {
    errorContainer.innerHTML = ''
  }

  const element = evt.detail.elt
  if (isButton(element)) {
    element.setAttribute('aria-busy', "true")
  }
})

// Handle htmx beforeSwap event
document.body.addEventListener('htmx:beforeSwap', function (evt) {
  if (evt.detail.xhr.status === 400 || evt.detail.xhr.status === 404 || evt.detail.xhr.status === 422 || evt.detail.xhr.status === 500) {
    // if the response code is 404, 422, or 500, we want to swap the content
    evt.detail.shouldSwap = true
    // set isError to 'false' to avoid error logging in console
    evt.detail.isError = false
  }

  if (evt.detail.xhr.status === 500) {
    if (!evt.detail.xhr.getResponseHeader('HX-Retarget')) {
      const errorContainer = document.getElementById('ErrorContainer')
      if (errorContainer) {
        evt.detail.target = errorContainer
      }
    }
  }
})

// Handle htmx responseError event
document.body.addEventListener('htmx:responseError', (evt) => {
  handleConfirmPromptButton(evt.detail.elt);
});

// Handle htmx afterRequest event
document.body.addEventListener('htmx:afterRequest', function (evt) {
  handleConfirmPromptButton(evt.detail.elt)
})

// Handle htmx afterOnLoad event for error scrolling. If the server responds with a header
// 'HX-Trigger: scrollToError', scroll to the first error on the page.
document.body.addEventListener('scrollToError', function (evt) {
  scrollToFirstError()
})

// Handle htmx afterSwap event
document.body.addEventListener('htmx:afterSwap', function (evt) {
  // console.log('htmx:afterSwap', evt.detail)
  // // Work around for scroll:top or scroll:window:top. For some reason,
  // // these aren't consistent, so we just force it on our own.
  // // Should be used with the usual `hx-swap` attribute (e.g. hx-swap="outerHTML scroll:top")
  // const target = evt.detail.target
  //
  // // Find either the hx-swap or the hx-reswap attribute
  // let swap = target.getAttribute('hx-swap')
  //
  // // Find hx-reswap in the headers
  // const reswap = evt.detail.xhr.getResponseHeader('hx-reswap')
  // if (reswap) {
  //   swap = reswap
  // }
  //
  // // console.log('evt', evt)
  // // console.log('target', target)
  // // console.log('swap is', swap)
  //
  // // if (target.hasAttribute('hx-swap')) {
  // if (swap) {
  //   // If the requestConfig.elt has a 'hx-disinherit' attribute, with 'hx-swap', ignore the scroll:top
  //   if (evt.detail.requestConfig?.elt.hasAttribute('hx-disinherit')) {
  //     const disinherit = evt.detail.requestConfig.elt.getAttribute('hx-disinherit')
  //     if (disinherit.includes('hx-swap') || disinherit === '*') {
  //       return
  //     }
  //   }
  //
  //   // const swap = target.getAttribute('hx-swap')
  //   const vals = ['scroll:top', 'scroll:window:top', 'scroll:bottom', 'scroll:window:bottom']
  //   if (vals.some(val => swap.includes(val))) {
  //     if (swap.includes('bottom')) {
  //       console.log('found bottom')
  //       window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
  //     } else {
  //       window.scrollTo({ top: 0, behavior: 'smooth' })
  //     }
  //   }
  //
  //   // If the swap contains a scroll to an element, like 'scroll:#some-id:bottom' or 'scroll:#some-id:top',
  //   // scroll to that element instead
  //   if (swap.includes('scroll:#')) {
  //     console.log('found scroll to element')
  //     const scrollTarget = swap.split(':').pop()
  //     const targetElement = document.querySelector(scrollTarget)
  //     if (targetElement) {
  //       const top = targetElement.offsetTop
  //       // const top = swap.includes(':top') ? 0 : targetElement.offsetTop
  //       window.scrollTo({ top: top, behavior: 'smooth' })
  //     }
  //   }
  // }

  // Handle flash messages
  showFlashMessages()
})

// Define all custom elements
const defineComponents = async (componentsMap = {}) => {
  const definePromises = []

  // Define and collect all custom elements
  for (const [name, elementClass] of Object.entries(componentsMap)) {
    if (!customElements.get(name)) {
      customElements.define(name, elementClass)
      const definePromise = customElements.whenDefined(name)
      definePromises.push(definePromise)
    }
  }

  // Wait for all custom elements to be defined
  await Promise.allSettled(definePromises)

  // Wait for any additional components to be defined
  if (config.waitForComponents.length > 0) {
    const waitForComponents = config.waitForComponents.map((componentName) => {
      return customElements.whenDefined(componentName)
    })

    await Promise.allSettled(waitForComponents)
  }
}

// Apply sortable to all elements with the .sortable class
const applySortable = async (element) => {
  const sortables = element.querySelectorAll('.sortable')
  for (let i = 0; i < sortables.length; i++) {
    const sortable = sortables[i]
    new Sortable(sortable, {
      animation: 150,
      ghostClass: 'sortable-ghost',

      // Make the htmx-indicator unsortable
      filter: '.htmx-indicator',

      onMove: function (evt) {
        return evt.related.className.indexOf('htmx-indicator') === -1
      },

      // Make sure sorting is re-enabled after the drag ends
      onEnd: function (evt) {
        this.option('disabled', false)
      }
    })

    // Re-enable sorting on the `htmx:afterSwap` event
    sortable.addEventListener('htmx:afterSwap', function () {
      this.sortable.option('disabled', false)
    })
  }
}

// On page load, define all components
document.addEventListener('DOMContentLoaded', async () => {
  await defineComponents(componentsMap)

  // Remove the preload class from the body to show the page
  document.body.classList.remove('preload')

  // Redefine all components on htmx load
  window.htmx.onLoad(async function (element) {
    await defineComponents(componentsMap)
    await applySortable(element)
  })

  // Look for any meta values with x-replace-url and replace the current URL with the new URL in the browser history
  const replaceUrlMeta = document.querySelector('meta[name="x-replace-url"]')
  if (replaceUrlMeta) {
    const newUrl = replaceUrlMeta.getAttribute('content')
    window.history.replaceState({}, '', newUrl)
  }

  // Handle flash messages
  showFlashMessages()
})
